
@media (orientation: landscape) {
    .landing-page-text {
        font-size: larger;
    }
}

@media (orientation: portrait) {
    .landing-page-text {
        font-size: larger;
    }
}