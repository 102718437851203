@media ((min-device-width: 1px) and (max-device-width: 991px)) {
    .NavbarWidthLabelColors {
        color: white;
    }
}

@media (min-device-width: 992px) {
    .NavbarWidthLabelColors {
        color: black;
    }
}

.NavbarWidthLabelColors {
    background-color: transparent;
}