/* #PresentationHeader{
    position: absolute;
    top: 10%;
} */

@keyframes animatedBackground {
    from {
      background-position: 50% 0%;
    }
    to {
      background-position: 80% 20%;
    }
  }
  #animate-area {
    background-image: url(http://placekitten.com/400/200);
    background-position: 0px 0px;
    background-repeat: repeat-x;
    animation: animatedBackground 10s linear infinite alternate;
  }

