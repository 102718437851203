@import url(//fonts.googleapis.com/css?family=Lato:300:400);

/* body {
  margin:0;
}

h1 {
  font-family: 'Lato', sans-serif;
  font-weight:300;
  letter-spacing: 2px;
  font-size:72px;
}
p {
  font-family: 'Lato', sans-serif;
  letter-spacing: 1px;
  font-size:14px;
  color: #333333;
} */

.admin-insta-carta-header {
  position:relative;
  text-align:center;
  background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
  color:white;
  z-index: 0;
}
.logo {
  width:100px;
  fill:white;
  padding-right:15px;
  display:inline-block;
  vertical-align: middle;
}

.admin-insta-carta-inner-header {
  height:70vh;
  width:100%;
  margin: 0;
  padding: 0;
}

.admin-insta-carta-flex { /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.admin-insta-carta-waves {
  position:relative;
  display: block;
  width: 100%;
  height:15vh; 
  z-index: -2;
}

.admin-insta-carta-content {
  position:relative;
  height:15vh;
  text-align:center;
  background-color: white;
}

/* Animation */

.admin-insta-carta-parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)   infinite;
}
.admin-insta-carta-parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.admin-insta-carta-parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.admin-insta-carta-parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.admin-insta-carta-parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

/*Shrinking height for mobile*/

@media (max-width: 50em) {
  .admin-insta-carta-waves {
    height: 17vw;
  }
}