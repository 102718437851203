.update-buttons {
    color: #f0ad4e ;
    cursor: pointer;
}

.delete-buttons {
    color: lightcoral;
    cursor: pointer;
}

.redirection-text {
    color: rgb(0, 119, 255)
}