.sticky-header-container {
    width: 80vw;
    margin: auto;
    /* width: 50%; */
    padding: 10px;
}

@media (orientation: portrait) {
    .sticky-header-container {
        width: 99vw;
        margin: auto;
        /* width: 50%; */
        padding: 10px;
    }
  }
  